<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="contractMenu">
        <list-menu @buttonClick="buttonClick" :menuData="menudata" ></list-menu>
    </div>
    <global-table :tableField="tableField" @previewFile="previewFile" @delFile="delFile" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>

    <el-dialog top="0px" title="上传签收单" :model-value="dialogModify1" width="40%">
    <div class="content">
      <div class="mainform">
        <div class="form">
          <el-form label-width="110px" class="demo-ruleForm">
            <el-form-item label="签收单" prop="sn">
              <el-upload
                ref="upload"
                name="file"
                :auto-upload="true"
                :action= 'postUrl'
                :on-preview="downBylob"
                :on-exceed="handleExceed"
                :on-remove="handleRemoveQsd"
                :on-success="handSuccess"
                :on-error="handError"
                :headers="tokenHeader"
                :file-list="upReturnData"
              >
                <el-button size="mini" type="primary" plain>+上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="mini" @click="dialogModify1 = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="edisUpData">确 定</el-button>
      </div>
    </template>
  </el-dialog>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData, menudata } from './js/setting.js'
import { baseURL, request, exportForm } from '@/assets/js/http.js'
import { fileHandle } from '@/assets/js/FileAction.js'
import { ElLoading } from 'element-plus'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'CabinetShipments',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      pageNum: 1,
      menudata: menudata,
      selectrowid: '',
      upReturnData: [],
      postUrl: baseURL + '/api/file/m/file/uploadToServer',
      dialogModify1: false,
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },
  mounted () {
    // 获取送货基地列表
    /*  var newOption = []
    request('/api/livraison/base/getBaseInfoByName?' + 'baseName=' + '', 'get').then((res) => {
      res.data.forEach(item => {
        newOption.push({
          label: item.baseName,
          value: item.baseCode
        })
      })
      this.childData.searchList[1].option = newOption
    }) */
  },
  methods: {
    previewFile (url) {
      window.open(url)
    },
    delFile (id) {
      this.$confirm('是否删除？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request('/api/file/m/file/delFile/' + id, 'get').then((res) => {
          if (res.code === '200') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.myBidData()
          }
        })
      }).catch(() => {
        this.$message({
          message: '操作取消',
          type: 'info'
        })
      })
    },
    buttonClick () {
      let rkksTime = ''
      let rkjsTime = ''
      if (this.childData.searchData.rkTime) {
        rkksTime = dayjs(this.childData.searchData.rkTime[0]).format('YYYY-MM-DD HH:mm:ss')
        rkjsTime = dayjs(this.childData.searchData.rkTime[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        ...this.childData.searchData,
        startTime: rkksTime,
        endTime: rkjsTime
      }
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      exportForm('/api/livraison/livrasion/straightHairOrderExport', 'post', obj, true).then(
        (response) => {
          try {
            const url = window.URL.createObjectURL(response.data)
            const eleLink = document.createElement('a')
            eleLink.href = url
            eleLink.click()
            window.URL.revokeObjectURL(url)
          } catch (error) {
            console.error('download function error!', error)
          }
          loading.close()
        })
    },
    edisUpData () {
      request('/api/livraison/livrasion/uploadFile/' + this.selectrowid, 'POST', this.upReturnData).then((res) => {
        if (res.code === '200') {
          this.$message({
            message: '上传成功',
            type: 'success'
          })
          this.dialogModify1 = false
          this.upReturnData = []
          this.myBidData()
        }
      })
    },
    downBylob (file) {
      fileHandle.handlePreview(file)
    },
    handleExceed (file) {

    },
    handError (file) {

    },
    handleRemoveQsd (file, list) {
      this.upReturnData = list
      console.log(arguments)
    },
    handSuccess (response, file, fileList) {
      if (response.code === '200') {
        // this.upReturnData = response.data[0]
        response.data.forEach(item => {
          this.upReturnData.push(item)
        })
      }
    },
    // 获取采购交付-送货需求列表数据
    myBidData () {
      let rkksTime = ''
      let rkjsTime = ''
      if (this.childData.searchData.rkTime) {
        rkksTime = dayjs(this.childData.searchData.rkTime[0]).format('YYYY-MM-DD HH:mm:ss')
        rkjsTime = dayjs(this.childData.searchData.rkTime[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        ...this.childData.searchData,
        startTime: rkksTime,
        endTime: rkjsTime
      }
      request('/api/livraison/livrasion/straightHairOrderList', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          // this.paginationData = { pageSize: pageSize, total: res.data.total }
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'uploadfile') {
        this.selectrowid = data.id
        this.dialogModify1 = true
      }
      if (clickBtn.operationButton === 'offer') {
        this.deliverGoods(data)
      }
      if (clickBtn.operationButton === 'rtdate') {
        this.reDate(data)
      }
    },
    // 预计送达日期
    reDate (data) {
      this.$prompt('确认反馈可发货日期？请输入预计发货日期：', '反馈可发货日期', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'date',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm' && (instance.inputValue ?? '').trim() === '') {
            this.$message({
              message: '请选择预计发货日期！',
              type: 'error'
            })
          } else {
            done()
          }
        },
        type: 'warning'
      }).then(({ value }) => {
        this.reDateFo(value, [data])
      })
    },
    // 发货弹框
    deliverGoods (data) {
      this.$prompt('确认要发货吗？请输入预计送达日期：', '发货', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'date',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm' && (instance.inputValue ?? '').trim() === '') {
            this.$message({
              message: '请选择预计送达日期！',
              type: 'error'
            })
          } else {
            done()
          }
        },
        type: 'warning'
      }).then(({ value }) => {
        this.deliverFo(value, [data])
      })
    },
    // 请求发货
    reDateFo (date, data) {
      const phdata = []
      for (const row of data) {
        phdata.push({ id: row.id, time: date })
      }
      request('/api/livraison/livrasion/updatePredictSendDate', 'POST', phdata).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          })
          this.myBidData()
        }
      })
    },
    // 请求发货
    deliverFo (date, data) {
      const phdata = []
      for (const row of data) {
        phdata.push({ id: row.id, time: date })
      }
      request('/api/livraison/livrasion/sendOrder', 'POST', phdata).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          })
          this.myBidData()
        }
      })
    },
    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
.contractMenu{
    float: right;
}
</style>
