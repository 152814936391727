export var SearchList = [
  { labe: '物料号', code: 'matnrh', type: 'input' },
  { labe: '物料描述', code: 'maktx', type: 'input' },
  {
    labe: '期望发货日期',
    code: 'rkTime',
    type: 'daterange'
  },
  { labe: '供应商编号', code: 'lifnr', type: 'input' },
  { labe: '供应商名称', code: 'supplierName', type: 'input' },
  { labe: '送达方名称', code: 'name1', type: 'input' }
]
export var SearchData = {
  livraOrderCode: '',
  baseCode: '',
  providerInfo: '',
  demandCode: '',
  status: '',
  rkTime: ''
}

export var tableField = [
  { label: '销售订单号', code: 'vbeln', type: 'input', width: '120px' },
  { label: '行项目', code: 'posnr', type: 'input', width: '70px' },
  { label: 'OA发货申请编号', code: 'zoanum', type: 'input', width: '200px' },
  { label: 'OA发货单编号', code: 'zsdnum', type: 'input', width: '120px' },
  { label: '物料号', code: 'matnrh', type: 'input', width: '100px' },
  { label: '物料描述', code: 'maktx', type: 'input', width: '240px' },
  { label: '期望发货日期', code: 'zfield2', type: 'input', width: '120px' },
  { label: '直发采购订单信息', code: 'zfield3', type: 'input', width: '180px' },
  { label: '申请发货数量', code: 'kwmeng', type: 'input', width: '110px' },
  { label: '供应商编号', code: 'lifnr', type: 'input', width: '120px' },
  { label: '机柜供应商名称', code: 'supplierName', type: 'input', width: '180px' },
  { label: '预计发货日期', code: 'predictSendDate', type: 'input', width: '180px' },
  { label: '发货日期', code: 'actDate', type: 'input', width: '180px' },
  { label: '预计送达日期', code: 'estimatedDelivery', type: 'input', width: '180px' },
  {
    label: '客户签收文件',
    code: 'fileInfoList',
    type: 'vnode',
    width: '150px',
    handle: function (scope) {
      if (scope) {
        return {
          data () {
            return {
              fileList: scope.row.fileInfoList,
              scope: scope
            }
          },
          methods: {
            previewFile (url) {
              this.$emit('previewFile', url)
            },
            delFile (delid) {
              this.$emit('delFile', delid)
            }
          },
          template: '<div>' +
            '<div style="height:20px;display:flex;align-items:center;" :key="val.id" v-for="val in fileList">' +
              '<el-button @click="previewFile(val.url)" style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;width: calc( 100% - 20px);min-height:0px;padding-top:0;padding-bottom:0;" type="text">{{val.name}}</el-button>' +
              '<el-button @click="delFile(val.id)" style="margin-left: 0px;" type="text" icon="el-icon-close"></el-button>' +
            '</div>' +
          '</div>'
        }
      }
    }
  },
  { label: '签收回传日期', code: 'receiptDate', type: 'input', width: '180px' },
  { label: '送达方编号', code: 'zoasdf', type: 'input', width: '100px' },
  { label: '送达方名称', code: 'name1', type: 'input', width: '200px' },
  { label: '联系人', code: 'remark', type: 'input', width: '100px' },
  { label: '电话', code: 'telNumber', type: 'input', width: '100px' },
  { label: '发货地址', code: 'stras', type: 'input', width: '240px' },
  { label: '运输方式', code: 'zysfs', type: 'input', width: '80px' },
  { label: '申请提交日期', code: 'actDatum', type: 'input', width: '120px' },
  { label: '申请提交时间', code: 'actUzeit', type: 'input', width: '120px' },
  { label: '申请链接', code: 'zoalink', type: 'input', width: '180px' },
  {
    label: '操作',
    code: 'act',
    width: '160px',
    type: 'action',
    fixed: 'right',
    actionData: [
      {
        actionLabel: '反馈可发货日期',
        operationButton: 'rtdate',
        stateField: 'status',
        stateSymbol: '!=',
        stateValue: '2'
      },
      {
        actionLabel: '发货',
        operationButton: 'offer',
        stateField: 'status',
        stateSymbol: '!=',
        stateValue: '2'
      },
      {
        actionLabel: '上传签收单',
        operationButton: 'uploadfile',
        stateField: 'status',
        stateSymbol: '=',
        stateValue: '2'
      }
    ]
  }
]

export var tableData = []

export var menudata = [{
  label: '导出',
  action: 'draftContract',
  id: 1
}
]
